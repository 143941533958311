import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Container, Row, Input, Button} from 'mdbreact';
import Modal from "react-responsive-modal";
import API from "../../../Constants/Api";

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            body: '',
            header: '',
            redirect: '',
            status: '',
            open: false
        }
    }

    state = {
        open: false
    };

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
        if (this.state.status) {
            this.setState({redirect: true});
        }
    };

    changeHandler = (event) => {
        this.setState({...this.state, [event.target.name]: event.target.value})
    }
    submitHandler = (event) => {
        event.preventDefault();
        let data = {
            email: this.state.email
        };
        let $this = this;
        API.post('FORGOT_PASSWORD', data, function (response) {
            if (response.data.success) {
                let header = 'Succès';
                let body = 'Un mail a été envoyé avec succès, veuillez vérifier votre boîte mail';
                $this.setState({body: body});
                $this.setState({header: header});
                $this.setState({status: true});
            } else {
                let header = 'Erreur';
                let body = 'L\'utilisateur avec cet adresse mail n\'existe pas';
                $this.setState({body: body});
                $this.setState({header: header});
                $this.setState({status: false});
            }
            $this.onOpenModal();
        });
    }
    render() {
        const {open} = this.state;
        if (this.state.redirect && this.state.status) {
            return <Redirect to='/login'/>
        }
        return (
            <div className="login-content login-bg">
                <div className="btp-form-containers">
                    <Container>
                        <Row>
                            <div className="col-lg-5 col-xl-5 col-md-12">
                                <div className="vertical-centered">
                                    <form autoComplete="off" onSubmit={this.submitHandler}>
                                        <p className="h1 text-left title-login mb-5">Mot de passe oublié <span></span></p>
                                        <div className="black-text mb-4 font-large">
                                            <Input label="Adresse mail"
                                                   name='email'
                                                   onChange={this.changeHandler} type="email"
                                                   className="form-control"
                                                   required autoComplete='off' size="lg"/>
                                        </div>
                                        <div className="text-left wf mb-3">
                                            <Button type="submit" className="btp-login-button btn-block">Envoyer</Button>
                                        </div>
                                        <p className="text-center">
                                            <Link className="link-forget" to="/Login">Connexion</Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-7 col-xl-7 col-md-12 full-height column_switch">
                                <div className="logo-login-wrapper vertical-centered">
                                    <div className="logo-login-container">
                                        <img src="img/logo.png" className="mb-4" alt="logo"/>
                                        <p className="text-center">
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Modal classNames={{overlay: 'custom-overlay', modal: 'mw6'}} open={open} onClose={this.onCloseModal} center>
                            <div className="check-paswword">
                                <i className="material-icons">
                                    check_circle_outline
                                </i>
                            </div>
                            <h2>{this.state.header}</h2>
                            <p>
                                {this.state.body}
                            </p>
                        </Modal>

                    </Container>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
