import React, {Component} from 'react';
import {Spinner, Container} from "mdbreact";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import Content from "../components/Content";
import axios from "axios";
import API from "../Constants/Api";
import {Redirect} from "react-router-dom";

class DefaultLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isLoggedIn: false,
            currentUser: {},
            sideBar: true,
            overlay: false
        };
        this.toggleSideBar = this.toggleSideBar.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);

    }

    checkUserLogin() {
        var API_KEY = localStorage.getItem('API-KEY');
        if (!API_KEY) {
            this.setState({isLoaded: true});
        } else {
            const $this = this;
            axios.defaults.headers.common['API-KEY'] = localStorage.getItem('API-KEY');
            axios.defaults.headers.common['UID'] = localStorage.getItem('uid');
            axios.defaults.headers.common['U-TYPE'] = localStorage.getItem('U-TYPE');
            axios.get(API.getApiRoute('CHECK_USER_BY_API_KEY')).then(function (response) {
                if (response.data.success) {
                    $this.setState({isLoggedIn: true});
                }
                $this.setState({isLoaded: true});
            });
        }
    }
    updateDimensions () {
        this.setState({sideBar: window.innerWidth > 992 ? true : false, overlay: window.innerWidth <= 992 ? true : false});
    }
    componentWillMount(){
        this.updateDimensions();
        this.setState({sideBar: window.innerWidth > 992 ? true : false, overlay: window.innerWidth <= 992 ? true : false});
    }

    componentDidMount() {
        this.checkUserLogin();
        window.addEventListener("resize", this.updateDimensions);
        if (this.state.sideBar)
          this.toggleSideBar();
    }
    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
    }

    toggleSideBar() {
        this.setState({sideBar: !this.state.sideBar});
    }

    render() {
        let mainClasses = this.state.sideBar ? '' : ' no-padding';
        let sideBarOverlay = this.state.sideBar && this.state.overlay ? 'sidebar-overlay' : '';
        if (!this.state.isLoaded) {
            return (
                <div className="intro-loader">
                    <Spinner crazy multicolor big/>
                </div>
            );

        } else {
            if (this.state.isLoggedIn) {
                return (
                    <div>
                        <button onClick={this.toggleSideBar} className="sidebar-toggle">
                            <i className="material-icons">menu</i>
                        </button>
                        <Header/>
                        <main className={'fixed-sn' + mainClasses}>
                            <SideBar className={this.state.sideBar ? 'showed-sidebar' : 'hidden-sidebar'}/>
                            <div className={sideBarOverlay}></div>
                            <Container fluid style={{minHeight: 900}} className="mt-0 container-body">
                                <Content/>
                            </Container>
                            <Footer/>
                        </main>
                    </div>
                );
            } else {
                /*get all route*/
                var currentPath = window.location.pathname;
                var routes = ['/login', '/forgot-password', '/reset', '/'];
                if (routes.indexOf(currentPath) > -1) {
                    return <Redirect to='/login'/>
                } else {
                    return <Redirect to='/error-404'/>
                }
            }
        }
    }
}

export default DefaultLayout;
