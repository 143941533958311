import axios from "axios";
const API = {
  //BASE_URL: "http://127.0.0.1/btpsavoie-intra/",
  // FRONT_URL: "https://intra.btpsavoie.fr/",
  // BASE_URL: "https://api.btpsavoie.fr/",
  FRONT_URL: "https://intra.btpsavoie.fr/",
  //FRONT_URL: "https://rec.btpsavoie.dev-leviatan.fr/",
  //BASE_URL: "http://local.api.btp-savoie.com:8888/",
  BASE_URL: "https://api.btpsavoie.fr/",
  //BASE_URL: "https://rec.api.btpsavoie.dev-leviatan.fr/",
  //BASE_URL: "https://rec.api.btpsavoie.dev-leviatan.fr/",
  routes: [
    {
      path: "orders/delete",
      name: "DELETE_ORDER",
      method: "DELETE"
    },
    {
      path: "contactTypes/listing",
      name: "CONTACTTYPES_LISTING",
      method: "GET"
    },
    {
      path: "contactTypes/add",
      name: "Add_CONTACT_TYPES",
      method: "POST"
    },
    {
      path: "contactTypes/update",
      name: "UPDATE_CONTACT_TYPES",
      method: "POST"
    },

    {
      path: "contacts/update",
      name: "UPDATE_CONTACT",
      method: "POST"
    },
    {
      path: "contactTypes/get/",
      name: "GET_CONTACTTYPES",
      method: "GET"
    },
    {
      path: "contacts/listing",
      name: "CONTACTS_LISTING",
      method: "GET"
    },
    {
      path: "permissions/listing",
      name: "PERMISSION_LISTING",
      method: "GET"
    },
    {
      path: "contactTypes/getMemberContactTypes",
      name: "GET_MEMBER_CONTACT_TYPES",
      method: "GET"
    },
    {
      path: "contactTypes/getProviderContactTypes",
      name: "GET_PROVIDER_CONTACT_TYPES",
      method: "GET"
    },
    {
      path: "contacts/get/",
      name: "GET_CONTACT",
      method: "GET"
    },
    {
      path: "providerContacts/get/",
      name: "GET_PROVIDER_CONTACT",
      method: "GET"
    },

    {
      path: "memberContacts/get/",
      name: "GET_MEMBER_CONTACT",
      method: "GET"
    },
    {
      path: "providerContacts/update",
      name: "UPDATE_PROVIDER_CONTACT",
      method: "POST"
    },
    {
      path: "memberContacts/update",
      name: "UPDATE_MEMBER_CONTACT",
      method: "POST"
    },
    {
      path: "providers/listing",
      name: "PROVIDERS_LISTING",
      method: "GET"
    },
    {
      path: "members/listing",
      name: "MEMBERS_LISTING",
      method: "GET"
    },
    {
      path: "providerContacts/add",
      name: "ADD_CONTACT_PROVIDER",
      method: "POST"
    },
    {
      path: "providerContacts/delete/",
      name: "DELETE_PROVIDER_CONTACT",
      method: "GET"
    },
    {
      path: "memberContacts/delete/",
      name: "DELETE_MEMBER_CONTACT",
      method: "GET"
    },
    {
      path: "contactTypes/delete/",
      name: "DELETE_CONTACT_TYPE",
      method: "GET"
    },
    {
      path: "orders/delete/",
      name: "DELETE_COMMANDE",
      method: "GET"
    },{
      path: "contactTypes/listing",
      name: "CONTACT_TYPES_LISTING",
      method: "GET"
    },
    {
      path: "providers/add",
      name: "ADD_PROVIDER",
      method: "POST"
    },
    {
      path: "providers/get/",
      name: "GET_PROVIDER",
      method: "GET"
    },
    {
      path: "providers/update",
      name: "UPDATE_PROVIDER",
      method: "POST"
    },
    {
      path: "providerContacts/getByProviderId/",
      name: "GET_CONTACTS_BY_PROVIDER_ID",
      method: "GET"
    }, {
      path: "providerContacts/add",
      name: "ADD_PROVIDER_CONTACT",
      method: "POST"
    }, {
      path: "members/getNotifcations",
      name: "MEMBERS_GET_NOTIFICATIONS",
      method: "GET"
    }, {
      path: "dashboard/listing",
      name: "DASHBOARD",
      method: "GET"
    }, {
      path: "security/forgot",
      name: "FORGOT_PASSWORD",
      method: "POST"
    }, {
      path: "security/reset",
      name: "RESET_PASSWORD",
      method: "POST"
    },{
      path: "account/get/",
      name: "GET_MY_Account",
      method: "GET"
    },{
      path: "account/update",
      name: "UPDATE_MY_ACCOUNT",
      method: "POST"
    },{
      path: "providers/delete/",
      name: "DELETE_PROVIDER",
      method: "GET"
    },{
      path: "users/delete/",
      name: "DELETE_USER",
      method: "GET"
    },{
      path: "contactExchanges/addOther",
      name: "ADD_CONTACT_EXCHANGE_OTHER",
      method: "POST"
    },{
      path: "contactExchanges/add",
      name: "ADD_CONTACT_EXCHANGE",
      method: "POST"
    },{
      path: "contactExchanges/listing",
      name: "GET_CONTACT_EXCHANGE",
      method: "GET"
    },{
      path: "contactExchanges/count",
      name: "GET_COUNT_CONTACT_EXCHANGE",
      method: "GET"
    },{
      path: "contacts/delete/",
      name: "DELETE_OTHER_CONTACT",
      method: "GET"
    },{
      path: "contacts/add",
      name: "ADD_OTHER_CONTACT",
      method: "POST"
    },{
      path: "contactTypes/getOtherContactTypes",
      name: "GET_OTHER_CONTACT_TYPES",
      method: "GET"
    },
    {
      path: "contacts/get/",
      name: "GET_OTHER_CONTACT",
      method: "GET"
    },{
      path: "contactExchanges/download",
      name: "DOWNLOAD_EXCHANGE_FILE",
      method: "GET"
    },{
      path: "tools/LinkDriveAccount",
      name: "GOOGLE_DRIVE_LINK",
      method: "POST"
    },
    {
      path: "members/getAllContactsExchangesByMemberID/",
      name: "CONTACTS_MEMBERS_EXCHANGES",
      method: "GET"
    },
    {
      path: "Subscriptions/add",
      name: "ADD_COTISATIONS",
      method: "POST"
    },
    {
      path: "Subscriptions/get",
      name: "COTISATIONS_LISTING",
      method: "POST"
    },
    {
      path: "Subscriptions/update",
      name: "UPDATE_COTISATIONS",
      method: "POST"
    },
    {
      path: "SubscriptionTypes/listing",
      name: "SUBSCRIPTIONS_TYPES_LISTING",
      method: "GET"
    },
    {
      path: "members/delete/",
      name: "DELETE_MEMBER",
      method: "DELETE"
    },
    {
      path: "members/add",
      name: "ADD_MEMBER",
      method: "POST"
    },
    {
      path: "memberContacts/getContactsByMemberId/",
      name: "CONTACT_MEMBER_LISTING",
      method: "GET"
    },
    {
      path: "memberContacts/get/",
      name: "GET_CONTACT_MEMBER_BYID",
      method: "GET"
    },
    {
      path: "memberContacts/delete/",
      name: "DELETE_CONTACT_MEMBER",
      method: "DELETE"
    },
    {
      path: "memberContacts/add",
      name: "ADD_CONTACT_MEMBER",
      method: "POST"
    },
    {
      path: "ContactTypes/getMemberContactTypes",
      name: "MEMBER_CONTACTS_TYPES_LISTING",
      method: "GET"
    },
    {
      path: "UploadFiles/upload",
      name: "UPLOAD_LOGO_MEMEBER",
      method: "POST"
    },
    {
      path: "members/update",
      name: "UPDATE_MEMBER",
      method: "POST"
    },
    {
      path: "members/get",
      name: "GET_MEMBER",
      method: "POST"
    }, {
      path: "members/listing",
      name: "MEMBER_LISTING",
      method: "GET"
    },
    {
      path: "members/listingInactive",
      name: "MEMBER_LISTING_INACTIVE",
      method: "GET"
    },

    {
      path: "members/get",
      name: "GET_MEMBER",
      method: "GET"
    },
    {
      path: "members/add",
      name: "ADD_MEMBER",
      method: "POST"
    },
    {
      path: "security/login",
      name: "LOGIN",
      method: "POST"
    },
    {
      path: "security/checkUserByApiKey",
      name: "CHECK_USER_BY_API_KEY",
      method: "GET"
    },
    {
      path: "security/getCsrfToken",
      name: "GET_CSRF_TOKEN",
      method: "GET"
    },
    {
      path: "users/get/",
      name: "GET_USERS",
      method: "GET"
    },
    {
      path: "users/update",
      name: "UPDATE_USERS",
      method: "POST"
    },
    {
      path: "users/listing",
      name: "USERS_LISTING",
      method: "GET"
    },
    {
      path: "users/add",
      name: "ADD_USERS",
      method: "POST"
    },
    {
      path: "orders/listing",
      name: "ORDERS_LISTING",
      method: "GET"
    },
    {
      path: "orders/get",
      name: "GET_ORDER",
      method: "GET"
    },
    {
      path: "orders/update",
      name: "UPDATE_ORDERS",
      method: "POST"
    },
    {
      path: "orders/add",
      name: "ADD_ORDERS",
      method: "POST"
    },
    {
      path: "providers/listing",
      name: "PROVIDERS_LISTING",
      method: "GET"
    },
    {
      path: "permissions/listing",
      name: "PERMISSIONS_LISTING",
      method: "GET"
    },
    {
      path: "tools/extractData",
      name: "TOOLS_EXTRACT_DATA",
      method: "GET"
    },
    {
      path: "tools/deleteData",
      name: "TOOLS_DELETE_DATA",
      method: "GET"
    },
    {
      path: "tools/countUserData",
      name: "TOOLS_GET_USER_DATA",
      method: "GET"
    }
  ],
  headers: {},
  getApiRoute: function (name = null) {
    for (var i in this.routes) {
      if (this.routes[i].name === name) {
        return this.BASE_URL + this.routes[i].path;
      }
    }
    return this.BASE_URL;
  },
  setHeaders: function (headers) {
    this.headers = headers;
  },
  instance: null,
  initAxios: function () {
    // to delete
    let apiRoutes = this.routes;
    this.routes = apiRoutes;

    this.instance = axios.create();
    this.instance.defaults.headers.common["Content-Type"] = "application/json";
    this.instance.defaults.headers.common["API-KEY"] = localStorage.getItem(
            "API-KEY"
    );
  },
  get: function (route, data, callback) {
    this.initAxios();

    this.instance
            .get(this.getApiRoute(route) + (data !== null ? data : ""))
            .then(function (response) {
              if (typeof callback === "function") {
                callback(response);
              }
            });
  },
  post: function (route, data, callback) {
    this.initAxios();

    this.instance.post(this.getApiRoute(route), data).then(function (response) {
      if (typeof callback === "function") {
        callback(response);
      }
    });
  },
  getItem: function (route, id, callback) {
    this.initAxios();
    this.instance
            .get(this.getApiRoute(route) + "/" + id)
            .then(function (response) {
              if (typeof callback === "function") {
                callback(response);
              }
            });
  },
  listing: function (route, params, callback) {
    this.initAxios();

    const defaultOrderBy = "asc";
    var urlParams = "";
    if (params !== null) {
      urlParams = typeof params.id !== "undefined" ? "/" + params.id : "";
      urlParams +=
              typeof params.orderBy !== "undefined"
                      ? "/" + params.orderBy
                      : defaultOrderBy;
      urlParams +=
              typeof params.limit !== "undefined" ? "/" + params.limit : "";
      urlParams += typeof params.page !== "undefined" ? "/" + params.page : "";
      urlParams +=
              typeof params.keywords !== "undefined" ? "/" + params.keywords : "";
    }

    this.instance
            .get(this.getApiRoute(route) + urlParams)
            .then(function (response) {
              if (typeof callback === "function") {
                callback(response);
              }
            });
  },
  update: function (route, id, data, callback) {
    this.initAxios();
    this.instance
            .post(this.getApiRoute(route) + "/" + id, data)
            .then(function (response) {
              if (typeof callback === "function") {
                callback(response);
              }
            });
  }
};

export default API;
