import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'material-design-icons/iconfont/material-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import registerServiceWorker from './registerServiceWorker';
ReactDOM.render(
    <App />,
    document.getElementById('root')
);

registerServiceWorker();
