import React from "react";
// import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import { Link } from "react-router-dom";

import { SideNav } from "mdbreact";
import Navigate from "../_nav";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      dropdownOpen: false,
      toggleStateA: false
    };
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleToggleClickA = this.handleToggleClickA.bind(this);
  }

  // Slide out buttons event handlers
  handleToggleClickA() {
    this.setState({
      toggleStateA: !this.state.toggleStateA
    });
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  clickedMobile = () => { 
    let isMobile = window.innerWidth <= 768;
    if (isMobile) { 
      document.querySelector('.sidebar-toggle').click();
    }
   // this.handleToggleClickA() ; 
  }

  render() {
    const sideStyle = { backgroundColor: "#6a9ed3", width: "100%" }; 
    return (
      <div className={this.props.className}>
        <div className="mt-5">
          <SideNav
            style={sideStyle}
            breakWidth={0}
            className="side-nav side-nav-bg mdb-sidenav fixed side-bar"
            onClick={this.clickedMobile}
          >
            <div />
            <div className="navigation-element">
              {Navigate.map((nav, idx) => {
                let type = localStorage.getItem("U-TYPE");
                var currentPath = window.location.pathname;
                var isadmin = "";
                if (
                  type !== "ADMIN" &&
                  (nav.url === "/users" || nav.url === "/outils")
                ) {
                  isadmin = " hidden";
                }
                var isactive = "";
                if (currentPath === nav.url) {
                  isactive = " active";
                }
                return (
                  <li className={isadmin + isactive} key={idx}>
                    <Link to={nav.url}>
                      <i className="material-icons">{nav.icon}</i>{" "}
                      <span>{nav.name}</span>
                    </Link>
                  </li>
                );
              })}
            </div>
          </SideNav>
        </div>
      </div>
    );
  }
}

export default SideBar;
