import React from 'react';
import {
    Navbar,
    NavbarNav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    CardText
} from 'mdbreact';
import Link from "react-router-dom/es/Link";
import API from "../Constants/Api";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false,
            toggleStateA: false,
            isLogout: false,
            currentUser: {},
            notifications: [],
            username: '',
            classNotif: false,
            avatar: 'avatar/avatar.png',
        };
        this.onClick = this.onClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleToggleClickA = this.handleToggleClickA.bind(this);
        this.clickNotif = this.clickNotif.bind(this);
    }

// Slide out buttons event handlers
    handleToggleClickA() {
        this.setState({
            toggleStateA: !this.state.toggleStateA
        })
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    getCurrentUser() {
        const $this = this;
        var id = localStorage.getItem('uid');
        API.get('GET_MY_Account', id, function (response) {
            if (response.data.success) {
                let data = response.data.data[0];
                localStorage.setItem('U-TYPE', data.profiles.type);
                $this.setState({
                    currentUser: data
                });
                $this.setState({
                    username: data.username
                });
                if (Object.keys(data).indexOf("collaborators") >= 0) {
                    if (data.collaborators.avatar) {
                        $this.setState({
                            avatar: data.collaborators.avatar
                        })
                    }
                } else {
                    if (data.admins.avatar) {
                        $this.setState({
                            avatar: data.admins.avatar
                        })
                    }
                }
            }
        });

    }

    getNotifications() {
        const $this = this;
        API.listing('MEMBERS_GET_NOTIFICATIONS', null, function (response) {
            if (response.data.success) {
                let notifications = response.data.data;
                $this.setState({notifications: notifications});
            }
        });
    }

    /*componentWillReceiveProps(nextProps) {


        if(nextProps.currentUser && nextProps.currentUser.username !== userURL) {
            this.getDbUser(userURL);
            console.log(userURL);
        }
    }*/

    logout() {
        localStorage.removeItem('API-KEY');
        localStorage.removeItem('uid');
        localStorage.removeItem('U-TYPE');
        window.location.href = '/login';
        //this.setState({isLogout: true})
    }

    componentDidMount() {
        this.getCurrentUser();
        this.getNotifications();
    }

    clickNotif() {
        const $this = this;
        $this.setState({
            classNotif: !$this.state.classNotif
        });
    }

    render() {
        var contentNotif;
        var nbrNotif = 0;
        if (this.state.notifications.length) {
            nbrNotif = this.state.notifications.length;
            contentNotif = this.state.notifications.map((notif,key) =>
                (
                    <span key={key}>
                        <span>
                            <i className="material-icons">warning</i>
                            L'inscription de l'adhérent <Link to={'/adherent/edit/' + notif.id}> {notif.companies.name} </Link> 
                            a été expirée.
                        </span>
                    </span>
                ))
        } else {
            contentNotif = <span className="text-center">
                Pas de notifications
            </span>
        }


        const BASE_URL = API.BASE_URL;
        const navStyle = {backgroundColor: "#fff", color: "#3f5c80", zIndex: 9999}
        const itemStyle = {color: "#3f5c80"}
        const specialCaseNavbarStyles = {WebkitBoxOrient: 'horizontal', flexDirection: 'row'};
        return (
            <div>
                <div className="mt-5">
                    <Navbar style={navStyle} dark expand="md" fixed="top" className="navbar-fixed-top">
                        <NavbarNav left>
                            <div className="left-nav">

                                    <Link to="/">
                                    <img src="/img/logo.png" height="24" alt="BTP SAVOIE"/>
                                    </Link>

                            </div>
                        </NavbarNav>
                        <NavbarNav right style={specialCaseNavbarStyles}>
                            <NavItem className="notification" onClick={this.clickNotif}>
                                <i className="material-icons">notifications_none</i>
                                <span>{nbrNotif}</span>
                            </NavItem>
                            <Card className={this.state.classNotif + ' body-notification'}>
                                <CardBody className="text-left">
                                    <CardText>
                                        {contentNotif}
                                    </CardText>
                                </CardBody>
                            </Card>
                            <NavItem>
                                <Dropdown>
                                    <DropdownToggle className="nav-dropdown" caret>
                                        <div style={itemStyle} className="d-none d-md-inline">
                                            <Link to="/" className="navbar-brand">
                                                <img className="avatar-user" src={BASE_URL + this.state.avatar} height="30" width="30" alt={""}/>
                                            </Link>
                                            <span>{this.state.username}</span>
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu right className="menu-right-account">
                                        <DropdownItem>
                                            <Link to='/myaccount'>
                                                <i className="material-icons">person</i><span> Mon compte</span>
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='#' onClick={this.logout}>
                                                <i className="material-icons">exit_to_app</i><span> Déconnexion</span>
                                            </Link>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem>
                        </NavbarNav>
                    </Navbar>
                </div>
            </div>
        )
            ;
    }
};

export default Header;