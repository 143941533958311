import React from "react";
import Loadable from "react-loadable";
import { Spinner } from "mdbreact";

function Loading() {
  return (
    <div className="text-center mt-80">
      <Spinner crazy yellow />
    </div>
  );
}

export const Dashboard = Loadable({
  loader: () => import("../views/Pages/Dashboard"),
  loading: Loading
});
export const MyAccount = Loadable({
  loader: () => import("../views/Pages/Account/MyAccount"),
  loading: Loading
});
export const Adherent = Loadable({
  loader: () => import("../views/Pages/Adherents/Adherent"),
  loading: Loading
});
export const AddAdherent = Loadable({
  loader: () => import("../views/Pages/Adherents/AddAdherent"),
  loading: Loading
});
export const EditAdherent = Loadable({
  loader: () => import("../views/Pages/Adherents/EditAdherent"),
  loading: Loading
});
export const Users = Loadable({
  loader: () => import("../views/Pages/Users/Users"),
  loading: Loading
});
export const AddUser = Loadable({
  loader: () => import("../views/Pages/Users/AddUser"),
  loading: Loading
});
export const EditUser = Loadable({
  loader: () => import("../views/Pages/Users/EditUser"),
  loading: Loading
});
export const Historique = Loadable({
  loader: () => import("../views/Pages/Commandes/Historique"),
  loading: Loading
});
export const EditCommande = Loadable({
  loader: () => import("../views/Pages/Commandes/EditCommande"),
  loading: Loading
});
export const AddCommande = Loadable({
  loader: () => import("../views/Pages/Commandes/AddCommande"),
  loading: Loading
});
export const Providers = Loadable({
  loader: () => import("../views/Pages/Providers/Providers"),
  loading: Loading
});
export const AddProvider = Loadable({
  loader: () => import("../views/Pages/Providers/AddProvider"),
  loading: Loading
});
export const Contacts = Loadable({
  loader: () => import("../views/Pages/Contacts/Contacts"),
  loading: Loading
});
export const ContactTypes = Loadable({
  loader: () => import("../views/Pages/Contacts/ContactTypes"),
  loading: Loading
});
export const AddContactTypes = Loadable({
  loader: () => import("../views/Pages/Contacts/AddContactTypes"),
  loading: Loading
});
export const EditProvider = Loadable({
  loader: () => import("../views/Pages/Providers/EditProvider"),
  loading: Loading
});
export const EditContactType = Loadable({
  loader: () => import("../views/Pages/Contacts/EditContactType"),
  loading: Loading
});
export const EditContact = Loadable({
  loader: () => import("../views/Pages/Contacts/EditContact"),
  loading: Loading
});
export const Error404 = Loadable({
  loader: () => import("../views/Pages/Errors/Error404"),
  loading: Loading
});
export const Error401 = Loadable({
  loader: () => import("../views/Pages/Errors/Error401"),
  loading: Loading
});
export const Outils = Loadable({
  loader: () => import("../views/Pages/Outils/Outils"),
  loading: Loading
});
export const AddContact = Loadable({
  loader: () => import("../views/Pages/Contacts/AddContact"),
  loading: Loading
});
export const EditProviderContact = Loadable({
  loader: () => import("../views/Pages/Contacts/EditProviderContact"),
  loading: Loading
});
export const EditMemberContact = Loadable({
  loader: () => import("../views/Pages/Contacts/EditMemberContact"),
  loading: Loading
});

export const routes = [
  { path: "/", exact: true, name: "Home", component: Dashboard },
  { path: "/myaccount", name: "MyAccount", component: MyAccount },
  { path: "/adherents", name: "Adhérents", component: Adherent },
  { path: "/adherent/add", name: "Ajouter Adhérent", component: AddAdherent },
  {
    path: "/adherent/edit/:memberId",
    name: "Editer Adhérent",
    component: EditAdherent
  },
  {
    path: "/provider/contact/edit/:providerContactId",
    name: "Edit Contact Provider",
    component: EditProviderContact
  },
  {
    path: "/adherent/contact/edit/:memberContactId",
    name: "Edit Contact Provider",
    component: EditMemberContact
  },
  { path: "/users", name: "Users", component: Users },
  { path: "/user/add", name: "Add Users ", component: AddUser },
  { path: "/user/edit/:userId", name: "Users", component: EditUser },
  { path: "/orders", name: "Users", component: Historique },
  { path: "/providers", name: "Providers", component: Providers },
  { path: "/provider/add", name: "Add Provider", component: AddProvider },
  { path: "/tools", name: "Outils", component: Outils },

  {
    path: "/provider/edit/:providerId",
    name: "Edit Provider",
    component: EditProvider
  },
  {
    path: "/order/add",
    name: "ajouter commande",
    component: AddCommande
  },
  {
    path: "/order/edit/:commandeId",
    name: "edit commande",
    component: EditCommande
  },
  {
    path: "/contact/add",
    name: "add contact",
    component: AddContact
  },
  {
    path: "/contact/types/edit/:contactTypeId",
    name: "edit contactType",
    component: EditContactType
  },
  {
    path: "/contact/types/add",
    name: "ajouter contact_types",
    component: AddContactTypes
  },
  {
    path: "/contact/types",
    name: "contact_types",
    component: ContactTypes
  },
  {
    path: "/contact/edit/:contactId",
    name: "edit contact",
    component: EditContact
  },
  {
    path: "/contacts",
    name: "contact",
    component: Contacts
  },
  { path: "/error-401", name: "Error 401", component: Error401 },

  { path: "*", name: "Error 404", component: Error404 }
];

export default routes;
