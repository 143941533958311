import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Navbar, NavbarNav, NavItem, NavbarBrand } from "mdbreact";
import API from "../../../Constants/Api";

class PageError extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const navStyle = {
      backgroundColor: "#fff",
      color: "#3f5c80",
      zIndex: 9999
    };
    return (
      <div className="container-fluid mt-5 container-body error-page">
        <Navbar
          style={navStyle}
          dark
          expand="md"
          fixed="top"
          className="navbar-fixed-top"
        >
          <NavbarNav left>
            <div className="left-nav">
              <NavbarBrand>
                <img alt="logo" src="/img/logo.png" height="24" />
              </NavbarBrand>
            </div>
          </NavbarNav>
          <NavbarNav right>
            <NavItem className="connexion-nav">
              <Link to="/login">
                <i className="material-icons">perm_identity</i>
                <span> Connexion</span>
              </Link>
            </NavItem>
          </NavbarNav>
        </Navbar>
        <div className="content">
          <div className="head">
            <div className="rgba-white-slight text-center">
              <div className="head">
                <div className="justify-content-md-center">
                  <div className="card-body text-center card-history">
                    <div className="title" data-wow-delay="0.4s">
                      Ooops... un problème est survenue
                    </div>
                    <h3 className="display-1 mt-5 mx-5 mt-lg-0">
                      PAGE INTROUVABLE
                    </h3>
                    <img
                      src={API.FRONT_URL + "img/404.png"}
                      className="mb-4 img-error"
                      alt="Eroor 404"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageError;
