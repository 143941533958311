import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Input, Button } from "mdbreact";
import API from "../../../Constants/Api";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      EmailError: true,
      isLoggedIn: false,
      remember: false
    };
  }

  changeHandlerRemember = () => {
    this.setState({ remember: !this.state.remember });
  };
  submitHandler = event => {
    event.preventDefault();
    var data = {
      email: this.state.email,
      password: this.state.password
    };
    API.post(
      "LOGIN",
      data,
      function(response) {
        this.setState({ EmailError: true });
        if (response.data.success) {
          localStorage.setItem("API-KEY", response.data.data.api_key);
          localStorage.setItem("uid", response.data.data.id);
          localStorage.setItem("U-TYPE", response.data.data.profiles.type);
          this.setState({ isLoggedIn: true });
        } else {
          this.setState({ EmailError: false });
        }
      }.bind(this)
    );
  };
  changeHandler = event => {
    //this.setState({EmailError: 'display-none'});
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  render() {
    if (!this.state.isLoggedIn) {
      return (
        <div className="login-content login-bg">
          <div className="btp-form-containers">
            <Container>
              <Row>
                <div className="col-lg-5 col-xl-5 col-md-12">
                  <div className="vertical-centered">
                    <form onSubmit={this.submitHandler}>
                      <p className="h1 text-left title-login mb-5">
                        Connexion <span />
                      </p>
                      <div className="black-text mb-4 font-large">
                        <Input
                          label="Adresse mail"
                          name="email"
                          onChange={this.changeHandler}
                          type="email"
                          className="form-control"
                          required
                          autoComplete="off"
                          size="lg"
                        />
                        <Input
                          label="Mot de passe"
                          name="password"
                          onChange={this.changeHandler}
                          type="password"
                          className="form-control"
                          required
                          autoComplete="off"
                          size="lg"
                        />
                      </div>
                      <div
                        className={!this.state.EmailError + " invalid-feedback"}
                      >
                        Merci de vérifier votre adresse mail ainsi que votre mot de passe
                      </div>
                      <div className="text-left wf mt-4">
                        <Button
                          className="btp-login-button btn-block"
                          type="submit"
                        >
                          Se connecter
                        </Button>
                      </div>
                    </form>
                    <p className="text-center forget-login mb-5">
                      Mot de passe oublié ?
                      <Link className="link-forget" to="/forgot-password">
                        {" "}
                        Réinitialiser
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="full-height col-lg-7 col-xl-7 col-md-12 column_switch">
                  <div className="logo-login-wrapper vertical-centered">
                    <div className="logo-login-container">
                      <img src="img/logo.png" className="mb-4" alt="logo" />
                      <p className="text-center"> 
                      </p>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Login;
