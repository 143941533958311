const Navigate = [
    {
        name: 'Tableau de bord',
        url: '/',
        icon: 'dashboard',
        badge: {
            variant: 'info',
            text: 'NEW',
        },
    }, {
        name: 'Adhérents',
        url: '/adherents',
        //icon: 'domain',
        icon: 'group',
    }, {
        name: 'Fournisseurs',
        url: '/providers',
        //icon: 'store_mall_directory',
        icon: 'local_shipping',
    }, {
        //name: 'Historique des commandes',
        name: 'Commandes ',
        url: '/orders',
        icon: 'shopping_cart',
    }, {
        name: 'Contacts',
        url: '/contacts',
        icon: 'perm_contact_calendar',
        //icon: 'email',
    }, {
        name: 'Utilisateurs',
        url: '/users',
        //icon: 'people',
        icon: 'person',
    },{
    name: 'Types de contact',
    url: '/contact/types',
    icon: 'contacts',
    //icon: 'email',
  },
    {
        name: 'Outils',
        url: '/tools',
        //icon: 'people',
        icon: 'settings',
    },
];
export default Navigate;