import React from "react";
import { Container, Footer as Foot } from "mdbreact";
import { Link } from "react-router-dom";

class Footer extends React.Component { 
    render(){
        return(
            <Foot color="white" className="font-small">
                <div className="footer-copyright white text-center py-3 text-dark">
                    <Container fluid>
                        &copy; <b><Link className="text-dark footer-link" to="/"> BTP Savoie {(new Date().getFullYear())} </Link></b>
                    </Container>
                </div>
            </Foot>
        );
    }

}

export default Footer;
