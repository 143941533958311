import React, { Component } from "react";
import { Container, Row, Input, Button } from "mdbreact";
import { Redirect, Link } from "react-router-dom";
import API from "../../../Constants/Api";
import Modal from "react-responsive-modal";
import queryString from "query-string";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirmPassword: "",
      token: "",
      header: "",
      redirect: "",
      status: "",
      open: false
    };
  }

  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    if (this.state.status) {
      this.setState({ redirect: true });
    }
  };

  onChange = event => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    if (this.state.password === this.state.confirmPassword) {
      let data = {
        token: this.state.token,
        password: this.state.password
      };
      let $this = this;
      API.post("RESET_PASSWORD", data, function(response) {
        if (response.data.success) {
          let header = "Success";
          let body = "Votre mot de passe a été réinitialisé avec succés!";
          $this.setState({ body: body });
          $this.setState({ header: header });
          $this.setState({ status: true });
        } else {
          let header = "Erreur";
          let body = "La mise à jour de votre mot de passe a échouée!";
          $this.setState({ body: body });
          $this.setState({ header: header });
          $this.setState({ status: false });
        }
        $this.onOpenModal();
      });
    }
  };

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.setState({ token: params.token });
  }

  render() {
    const { open } = this.state;
    const isEnabled =
      this.state.password === this.state.confirmPassword &&
      (this.state.password.length >= 8 &&
        this.state.confirmPassword.length >= 8);
    if (this.state.redirect && this.state.status) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="login-content login-bg">
        <div className="btp-form-containers">
          <Container>
            <Row>
              <div className="col-lg-5 col-xl-5 col-md-12">
                <div className="vertical-centered">
                  <form
                    autoComplete="off"
                    onSubmit={this.onSubmit}
                    className="needs-validation"
                    noValidate
                  >
                    <p className="h1 text-left title-login mb-5">
                      Réinitialisez votre mot de passe
                      <span />
                    </p>
                    <div>
                      <strong>NB :</strong> Le mot de passe doit contenir au moins 8 caractères
                    </div>
                    <div className="black-text mb-4 font-large">
                      <Input
                        label="Mot de passe"
                        size="lg"
                        type="password"
                        error="wrong"
                        name="password"
                        success="right"
                        required
                        className="form-control"
                        value={this.state.password}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="black-text mb-4 font-large">
                      <Input
                        label="Confirmation mot de passe"
                        size="lg"
                        type="password"
                        name="confirmPassword"
                        error="wrong"
                        success="right"
                        required
                        className="form-control"
                        value={this.state.confirmPassword}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="text-left wf mb-3">
                      <Button
                        disabled={!isEnabled}
                        onClick={this.onSubmit}
                        className="btp-login-button btn-block"
                      >
                        Envoyer
                      </Button>
                    </div>
                    <p className="text-center">
                      <Link className="link-forget" to="/Login">
                        Connexion
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
              <div className="col-lg-7 col-xl-7 col-md-12 full-height column_switch">
                <div className="logo-login-wrapper vertical-centered">
                  <div className="logo-login-container">
                    <img src="img/logo.png" className="mb-4" alt="logo" />
                    <p className="text-center">
                      
                    </p>
                  </div>
                </div>
              </div>
            </Row>
            <Modal
              classNames={{ overlay: "custom-overlay", modal: "mw6" }}
              open={open}
              onClose={this.onCloseModal}
              center
            >
              <div className="check-paswword">
                <i className="material-icons">check_circle_outline</i>
              </div>
              <h2>{this.state.header}</h2>
              <p>{this.state.body}</p>
            </Modal>
          </Container>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
