import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

// Containers
import DefaultLayout from './containers/DefaultLayout';
// Pages
import Login from './views/Pages/Auth/Login';
import ForgotPassword from './views/Pages/Auth/ForgotPassword';
import PageError from './views/Pages/Errors/PageError';
import ResetPassword from './views/Pages/Auth/ResetPassword';

class App extends Component {
    render() {
        return (
            <div>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/login" name="Login Page" component={Login}/>
                        <Route exact path="/forgot-password" name="Forgot Page" component={ForgotPassword}/>
                        <Route exact path="/reset" name="Forgot Page" component={ResetPassword}/>
                        <Route exact path="/error-404" name="Error 404" component={PageError}/>
                        <Route path="/" name="Home" component={DefaultLayout}/>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;